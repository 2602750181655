












































































































































import { Component, Vue } from 'vue-property-decorator';
import { Franchise, Office, OfficeExternalLinkType, WebsiteLevel } from 'client-website-ts-library/types';
import { API, Config, Logger, LogLevel } from 'client-website-ts-library/services';

@Component
export default class Footer extends Vue {
  private office: Office | null = null;

  mounted() {
    this.loadOffices();
  }

  loadOffices(): void {
    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.office = office;
    });
  }

  // eslint-disable-next-line
  scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  // eslint-disable-next-line
  get year(): string {
    return new Date().getFullYear().toString();
  }

  get hideMap(): boolean {
    return this.$route.meta?.hideOfficeMap;
  }

  get facebookLink(): string | undefined {
    return this.office?.ExternalLinks.find((l) => l.Type === OfficeExternalLinkType.Facebook)?.Url;
  }

  get youtubeLink(): string | undefined {
    return this.office?.ExternalLinks.find((l) => l.Type === OfficeExternalLinkType.YouTube)?.Url;
  }

  get twitterLink(): string | undefined {
    return this.office?.ExternalLinks.find((l) => l.Type === OfficeExternalLinkType.Twitter)?.Url;
  }

  get googleMyBusinessLink(): string | undefined {
    return this.office?.ExternalLinks.find((l) => l.Type === OfficeExternalLinkType.GoogleMyBusiness)?.Url;
  }

  get linkedinLink(): string | undefined {
    return this.office?.ExternalLinks.find((l) => l.Type === OfficeExternalLinkType.LinkedIn)?.Url;
  }

  get pinterestLink(): string | undefined {
    return this.office?.ExternalLinks.find((l) => l.Type === OfficeExternalLinkType.Pinterest)?.Url;
  }

  get instagramLink(): string | undefined {
    return this.office?.ExternalLinks.find((l) => l.Type === OfficeExternalLinkType.Instagram)?.Url;
  }
}
